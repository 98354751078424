import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`現在、「Magic AB」が公式サイトから`}<br parentName="p"></br>{`
`}{`謎の失踪を遂げています。`}<br parentName="p"></br>{`
`}{`ですので、「MCompare」を使うか、`}<br parentName="p"></br>{`
`}{`「Metric AB」というのも出ています。`}</p>
      <p><strong parentName="p">{`補足２：`}</strong></p>
      <p>{`Studio Oneの公式サイトにある通り、`}<br parentName="p"></br>{`
`}{`無料版のStudio One Primeでは`}<br parentName="p"></br>{`
`}<strong parentName="p">{`内蔵されている以外のプラグインを`}<br parentName="strong"></br>
          {`使うことができません。`}</strong></p>
      <p>{`MCompareなどを使う場合は、`}<br parentName="p"></br>{`
`}{`「体験版」を使う必要があります。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      